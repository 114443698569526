import React, { ReactElement } from 'react'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Hva er utstrålende smerter?</h1>
      <p>
        Utstrålende smerter er smerter som typisk beskrives som en strålende smerte ut i armer eller bein. Smerten kan
        også stråle til rygg eller ansikt. Den utstrålende smerten kommer fra muskler, skiver i mellom ryggvirvler eller
        i sjeldnere tilfeller fra organer som for eksempel hjertet. Problemet er som regel enten spente muskler med
        aktive triggerpunkter som gir strålende smerter, eller slitasje med rifter i en skive mellom ryggvirvler som gir
        utstrålende smerter. Kiropraktoren skal kunne kjenne igjen om smerten kommer fra muskel- og skjelettapparatet
        eller om man må henvise videre til lege/spesialist.
      </p>

      <p>
        Utstrålende smerter er noe en kiropraktor er veldig kjent med å behandle. Kommer smerten fra en muskel er
        behandlingen rask og effektiv. Kommer smerten fra en slitt skive kan behandlingen ta noe lenger tid, men være
        desto viktigere. Rifter i skiver som ikke behandles på rett måte med å normalisere leddbevegeligheten i området
        og styrke kjernemusklene vil ha økt risiko for å forverres i form av at man kan få skivebuktning eller
        skiveutglidning/prolaps. Hver behandling varer fra 20-30 minutt.
      </p>
    </Layout>
  )
}

export default Page
